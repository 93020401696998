
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Sidebar from '@/components/left-sidebar.vue'; // @ is an alias to /src
import RSidebar from '@/components/right-sidebar.vue'; // @ is an alias to /src
import productService from '@/services/product-service';
import PopUp from "@/components/PopUp.vue";
import utils from "@/utils/utils";
import Loader from "@/components/loader.vue"; // @ is an alias to /src

@Component({
  components: {
    Header,
    Sidebar,
    RSidebar,
    PopUp,
    Loader
  },
})
export default class ProductItem extends Vue {
  loading = true;
  private data: any[] = [];
  private currentItem: any = null;
  private currentIndex: number = -1;
  private title: string = "";
  private rows = 10000;
  private currentPage = 1
  public obj: any = {}

  public fields = [
    {
      key: 'id',
      label: "#",
      sortable: true
    },
    {
      key: 'sku',
      sortable: false
    },
    {
      key: 'title',
      sortable: false
    },
    {
      key: 'title_arabic',
      sortable: false
    },
    {
      key: 'descripton',
      label: 'Description',
      sortable: false
    },
    {
      key: 'no_of_pieces',
      sortable: false
    },
    {
      key: 'category',
      sortable: false,

    },
    {
      key: 'sub_category_level_1',
      sortable: false,

    },
    {
      key: 'sub_category_level_2',
      sortable: false,

    }
  ];

  public columns = [
    {
      label: 'sku',
      field: 'sku',
    },
    {
      label: 'title',
      field: 'title',
    },
    {
      label: 'title_arabic',
      field: 'title_arabic'
    },
    {
      label: 'descripton',
      field: 'description',
    },
    {
      label: 'no_of_pieces',
      field: 'no_of_pieces',
    },
    {

      label: 'category',
      field: 'category',

    },
    {
      label: 'sub_category_level_1',
      field: 'sub_category_level_1',

    },
    {
      label: 'sub_category_level_2',
      field: 'sub_category_level_2',

    }
  ];

  public items: any = []
  public ddl_category: any = []
  public ddl_category_1: any = []
  public ddl_category_2: any = []
  error: any = "";
  // For Modal
  modalShow = false;
  modalMessage: string[] = [];
  modalSimpleMessage: string = "";
  modalType = "";
  closeModal() {
    this.modalShow = false;
    this.modalMessage = [];
    this.modalType = "";
    this.modalSimpleMessage = ""
  }
  showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
    this.modalShow = true;
    this.modalMessage = array;
    this.modalType = type;
    this.modalSimpleMessage = message
  }
  // modal end
  retrieve() {
    this.loading = true;
    productService.get_item()
      .then((response) => {
        this.items = response.data;
        this.loading = false;
      })
      .catch((e) => {
        console.log(e);
      });
    productService.get_category('')
      .then((response) => {
        this.ddl_category = response.data;
        this.loading = false;
      })
      .catch((e) => {
        console.log(e);
      });
    productService.get_sub_level_1_category('')
      .then((response) => {
        this.ddl_category_1 = response.data;
        this.loading = false;
      })
      .catch((e) => {
        console.log(e);
      });
    productService.get_sub_level_2_category('')
      .then((response) => {
        this.ddl_category_2 = response.data;
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });

  }

  refreshList() {
    this.retrieve();
    this.currentItem = null;
    this.currentIndex = -1;
  }

  create() {
    let data = this.obj;
    this.error = ""
    const error_list = utils.getEmptyKeys(data, ['sku', 'title', 'title_arabic', 'description', 'no_of_pieces', 'category', 'sub_category_level_1', 'sub_category_level_2'])
    if (error_list.length > 0) {
      this.error = utils.generateErrorMessage(error_list)
    }
    else {
      this.loading = true;
      productService.create_item(data)
        .then((response) => {
          this.items = response.data;
          this.reset();
          this.retrieve();
          this.showModal("Created successfully", [], "success");
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log('Unexpected error:', e);
          this.showModal('An unexpected error occurred', [], "error");
        });
    }
  }
  mounted() {
    this.retrieve();
  }
  reset() {
    this.obj = {};
    this.error = "";
  }
}
